@font-face {
  font-family: 'Cerebri Sans';
  src: url('Cerebri-Sans-ExtraBold.ttf');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Cerebri Sans';
  src: url('Cerebri-Sans-Bold.ttf');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'Cerebri Sans';
  src: url('Cerebri-Sans-SemiBold.ttf');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Cerebri Sans';
  src: url('Cerebri-Sans-Medium.ttf');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Cerebri Sans';
  src: url('Cerebri-Sans-Regular.ttf');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Made Outer Sans';
  src: url('made_outer_sans_bold-webfont.woff') format('woff');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Made Outer Sans';
  src: url('made_outer_sans_regular-webfont.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}


@font-face {
  font-family: 'Made Outer Sans';
  src: url('made_outer_sans_light-webfont.woff') format('woff');
  font-weight: 200;
  font-style: normal;
}


@font-face {
  font-family: 'Made Outer Sans';
  src: url('made_outer_sans_thin-webfont.woff') format('woff');
  font-weight: 100;
  font-style: normal;
}

